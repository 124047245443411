#sayHelloButton {
  text-decoration: none;
  margin: 1vw 0vw;
  padding: 1vw 3vw;
  border: 1px solid #000;
  box-shadow: 6px 6px 0px 0px var(--box-shadow-color);
  color: #000;
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 22px;
  justify-content: center;
}
#leetcode{
  height: 45px;
}
#contacttitle {
  text-transform: lowercase;
  font-size: 1.8vw;
  margin: 0vw 4vw;
  width: 80%;
}
#contactsection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5vh;
  margin-top: 5vh;
}

#socialmedialinks {
  width: 100%;
  margin-top: 2vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 3vw;
  background-color: #000;
  padding: 4vw 0vw;
}
#socialmedialinks > a {

}
@media (max-width: 1200px) {
  #contacttitle {
    font-size: 3vw;
  }
  #sayHelloButton {
    width: 20%;
    padding: 2vw 1vw;
  }
}
@media (max-width: 800px) {
  #contacttitle {
    font-size: 4vw;
  }
  #sayHelloButton {
    width: 30%;
    font-size: 20px;
    height: 40px;
    padding: 2vw 1vw;
  }
}
@media (max-width: 600px) {
  #contacttitle {
    font-size: 20px;

  }
  #sayHelloButton {
    width: 60%;
    font-size: 18px;
    height: 40px;
    padding: 2vw 1vw;
  }
  #socialmedialinks {
    margin-top: 4vh;
    height: 80px;
    gap: 5vw;
  }
  #socialmedialinks > a > img {
    width: 28px;
    height: 28px;
  }
}
