:root {
  /* --main-bg-color: #eeeee6; */
  --main-bg-color: #fff;
  --box-shadow-color : #000000;
  -webkit-tap-highlight-color: transparent;
}
a {
  cursor: pointer;
  user-select: none;
}


body {
  margin: 0;
  padding: 0;
  font-family:  'IBM Plex Mono', 'Montserrat',sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-bg-color);
  /* background-color: #EFEFEF; */
  /* width: 86%; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
::selection {
	color: #EFEFEF;
	background-color: rgba(0, 0, 0, 1);
}
