/* MiniCircle.css */
#minicircle {
  width: 16px;
  height: 16px;
  position: fixed;
  z-index: 1;
  border-radius: 50%;
  pointer-events: none;
  /* top: -56px;
  left: -56px; */
  pointer-events: none;
  mix-blend-mode: difference;
  background: white;
  
}



@media (max-width : 600px) {
  #minicircle {
    display: none;
  }
}
