#experience {
    display: flex;
    justify-content: space-between;
    margin: 0vh 10vw;
    text-align: start;
}
#experiences {
    margin: 5vw 0vh;
}

#expdividersection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#expdividerline {
    height: 100%;
    min-height: 10px;
    width: 2px;
    display: flex;
    background-color: #000;
}

#expdividercircle {
    width: 20px;
    height: 22px;
    border-radius: 50%;
    background-color: #000;
}
#leftsection, #rightsection {
    width: 40%;
    align-self: center;
    padding: 2vw;
}

#companyname {
    font-size: 30px;
    margin-bottom: 10px;
}

#role {
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 1px;
    margin-bottom: 30px;
}

#description {
    letter-spacing: 1px;
    line-height: 1.5;
}

@media (max-width:750px) {
    #leftsection, #rightsection {
        width: 80%;
        align-self: flex-start;
        padding : 2vh 0vw;
    }
    #description {
        letter-spacing: 0px;
        line-height: 1.4;
        font-size: 14px;
    }
    #duration {
        font-size: 14px;
        letter-spacing: 0px;
    }
    #role {
        font-weight: 500;
        font-size: 16px;
        letter-spacing: -0.2px;
        margin-bottom: 30px;
    }
    #experience {
        justify-content: space-around;
        margin: 0vh 5vw;
    }
    #companyname {
        font-size: 22px;
        font-weight: 400;
    }
    #experiences {
        margin-top: 6vh;
    }
}