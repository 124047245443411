#firstname,
#lastname,
#lastnamesquare {
  font-family: "Michroma";
  font-size: 4vw;
}
#firstnamesection {
  margin-top: 12vw;
}
#hero {
  margin: 20vh 0vw;
  margin-bottom: 10vw;
}

#designation {
  font-size: 24px;

  color: #a7a4a4;
}

#square {
  height: 3vw;
  width: 36%;
  background-color: black;
  margin-left: -12.76vw;
  overflow: hidden;
  display: flex;
  mix-blend-mode: difference;
  justify-content: start;
}

#lastnamesection {
  display: flex;
  justify-content: end;
  align-items: end;

}
#firstnamesection {
  display: flex;
  justify-content: center;
  align-items: end;
  z-index: 0;
  position: relative;
}

#lastnamesquare {
  color: #fff;
  /* position: absolute; */
  margin-top: -2.8vw;
}

#viewresume {
  border: 1px solid #000;
  /* box-shadow: 4px 4px 0px 1px var(--box-shadow-color); */
  color: #000;
  padding: 22px 60px;
}


#sayhello {
  background-color: #000;
  padding: 22px 80px;
  color: #fff;
}
#actionbuttons {
  margin: 8vw 1vw;
  display: flex;
  justify-content: center;
  align-items: end;
  font-size: 20px;
}
#actionbuttons > a {
  text-decoration: none;
  margin: 0vw 1vw;

}



@media (max-width: 1200px) {
  #viewresume {
    padding: 20px 40px;
    font-size: 18px;
  }
  #sayhello {
    padding: 20px 60px;
    font-size: 18px;
  }
}
@media (max-width: 800px) {
  #designation {
    font-size: 20px;
  }
  #actionbuttons > a {
    font-size: 16px;
    margin: 0vw 2vw;
  }
  #viewresume {
    padding: 18px 40px;
  }
  #sayhello {
    padding: 18px 54px;
  }
}

@media (max-width: 600px) {
  #designation {
    font-size: 16px;
  }
  #firstname,
  #lastname,
  #lastnamesquare {
    font-family: "Michroma";
    font-size: 9vw;
  }

  #lastnamesquare {
    margin-top: -6.2vw;
  }

  #square {
    height: 6.6vw;
    width: 35%;
    margin-left: -28.5vw;
  }

  #actionbuttons > a {
    font-size: 14px;
    margin: 12vw 2vw;
  }
  #viewresume {
    padding: 14px 26px;
  }
  #sayhello {
    padding: 14px 34px;
  }
  #firstnamesection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    width: 80%;
  }
  #hero {
    margin-bottom: 10vh ;
  }
}
